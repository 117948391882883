.go-back-from-dashboard {
    display: flex;
    margin-top: 32px;
    padding: 0px;
}

.w-40 {
    width: 40% !important;
}

.align-date-range {
    min-width: 350px;
    margin-left: 16px;
    align-items: center;
}

.align-search-bar {
    min-width: 300px;
}

.vl {
    border-left: 1px solid #e5e6eb;
    height: 100%;
    position: absolute;
    right: 0;
    margin-left: -3px;
    top: 0;
}

.outer-box {
    padding: 8px 15px 0px;
}

.inner-box {
    border: 1px solid #E5E6EB;
    border-radius: 4px;
    padding: 10px;
}

.op-border-left {
    border-left: 1px solid #e5e6eb;
}

.narratives {
    table {

        td,
        th {
            padding: map-get($spacers, 2);
        }

        p {
            line-height: 1;
        }
    }
}

.position-fix {
    input {
        position: absolute !important;
    }
}

.empty-state-align {
    margin: auto;
    padding: 2rem;
    width: 60%;
    text-align: center;
}

.mouse-hover-effect {
    border: 1px solid #006FF9;
    background: #E6F1FE;
}

.align-narrative-title {
    z-index: 50;
    margin-left: 24px;
    color: #006FF9;
    cursor: pointer;

    svg {
        margin-top: -5px;
    }
}

.custom-narrative-pagination {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: white;
}

.set-max-content {
    min-width: max-content;
}

.set-button-content {
    min-width: max-content;
    height: 42px;
}

.set-button-loader {
    min-width: 165px;
}

.align-icon-top {
    margin-top: -62px;
}

.align-dashboard-translation-icon {
    margin-top: 3px;
}

.dashboard-back-button {
    text-decoration: none !important;

    span {
        margin-top: -3px;
    }
}

.set-dropdown-width {
    min-width: 10px !important;
}

.watchlist-table {
    min-width: max-content;
    display: block;
}

.set-max-height {
    max-height: 450px;
    overflow: scroll;
}

.set-view-type {
    border: 1px solid #E5E6EB;
    border-radius: 4px;
    cursor: pointer;
}

.ai-clustering {
    background: #E6F1FE;

    .scrollbar-small::-webkit-scrollbar-track {
        background-color: #FFFFFF;
    }

    .cursor-default canvas {
        cursor: default !important;
    }
}

.mh-content-page-n4 {
    min-height: calc(100vh - (#{$topbar-height} * 2) + 5px - map-get($spacers, 4));
}

.zoom-capabitlity {
    overflow: hidden;
}

.zoom-capabitlity:hover {
    transform: scale(1.5);
    transition: width 2s, height 2s, transform 3s;
}

.cluster-stats-align {
    display: flex;
    width: auto;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    margin-top: 16px;
}

.cluster-stat-container {
    border-radius: 4px;
    padding: 8px 16px;
    border: 1px solid var(--border-border-action, #E5E6EB);

    &.disabled {
        border-color: $border-primary;
    }
}

body>.highcharts-tooltip-container {
    transform: scale(1) !important;
    z-index: 99 !important;
}

.ai-clustering-tooltip {
    &>span {
        display: block;
        white-space: unset !important;
    }
}

.place-on-top {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.ml-45 {
    margin-left: 38%;
}

.button-props {
    width: 60px;
    border-radius: 4px;
    height: 40px;
    color: white;
    margin-left: 8px;
    background-color: #006FF9;
    margin-top: 8px;
}

.subClusterList {
    border: 2px solid #E5E6EB;
    border-radius: 4px;
}

.set-underline-props {
    height: 1px;
    background-color: #E5E6EB;
    margin: 0;
}

.cluster-wordcloud {
    border: 2px solid #E5E6EB;

    div {
        height: 10%;
    }
}

.beta-label {
    width: 48px;
    height: 24px;
    margin-left: 16px;
    border-radius: 4px;
    padding: 2px 6px !important;
    background-color: #00C0C7;
    color: black;
}

.beta-label-summary {
    width: 52px;
    height: 28px;
    margin-left: 16px;
    border-radius: 4px;
    padding: 2px 6px !important;
    background-color: #00C0C7;
    color: black;
}

.font-14 {
    font-size: 14px !important;
}

.selected-cluster-height {
    max-height: calc(100vh - 388px);
}

.ai-narratives-list-view .selected-cluster-height {
    max-height: calc(100vh - 275px);
}

.selected-cluster-list-view-height {
    max-height: calc(100vh - 75px);
}

.widget-text-style{
    border-bottom: 1px dashed black;
    text-decoration: none;
    width: fit-content;
}

.scroll-up-button {
    position: fixed;
    bottom: 32px;
    right: 32px;
    z-index: 1;
}

.fixed-to-top {
    position: sticky;
    top: 0;
    z-index: 99;
}
.summary-button{
    bottom: 16px;
    position: absolute;
    padding: 0;
}

.viewed-item {
    color: #9C50FD;
}

.viewed-icon {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #E6F1FE;
    width: 50%;
}

.actors-modal {
    min-height: calc(80vh - 400px);
    max-height: calc(80vh - 100px);
    overflow-y: auto;
}

.threats-coordination-modal {
    max-height: calc(80vh - 10px);
    overflow-y: auto;
}

.hover-state {
    background-color: $lightgray;
    transition: background-color 0.3s ease/* For smooth transition effects */
  }
  
.hover-state:hover {
    background-color: $primary-light; /* Change to any hover color you prefer */
}
  